export default function () {
  //if (process.env.baseUrl !== 'http://localhost:3000') {
  ;(function (w, d, s, l, i, u) {
    w[l] = w[l] || []
    w[l].push({ event: 'cust.gtmUrl', gtmLoadUrl: u })
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = u + '/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(
    window,
    document,
    'script',
    'dataLayer',
    'GTM-PTQKWD',
    'https://gtms.trkkn.com'
  )
  //}
}
