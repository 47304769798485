export default {
  data() {
    return {
      Mixin_metaFields: [],
      Mixin_dataPageTitle: 'TRKKN!'
    }
  },
  computed: {
    MixinMageTitle() {
      return this.Mixin_dataPageTitle
    },
    MixinMetaData() {
      return this.Mixin_metaFields
    }
  },
  methods: {
    setMetaData(meta_fields) {
      if (meta_fields.title !== undefined && meta_fields.title !== '') {
        this.Mixin_dataPageTitle = meta_fields.title
      }
      if (
        meta_fields.description !== undefined &&
        meta_fields.description !== ''
      ) {
        this.Mixin_metaFields.push({
          hid: 'description',
          name: 'description',
          content: meta_fields.description
        })
      }
      if (meta_fields.og_title !== undefined && meta_fields.og_title !== '') {
        this.Mixin_metaFields.push({
          property: 'og:title',
          content: meta_fields.og_title
        })
      }
      if (
        meta_fields.og_description !== undefined &&
        meta_fields.og_description !== ''
      ) {
        this.Mixin_metaFields.push({
          property: 'og:description',
          content: meta_fields.og_description
        })
      }
      if (meta_fields.og_image !== undefined && meta_fields.og_image !== '') {
        this.Mixin_metaFields.push({
          property: 'og:image',
          content: meta_fields.og_image
        })
      }
      if (
        meta_fields.twitter_title !== undefined &&
        meta_fields.twitter_title !== ''
      ) {
        this.Mixin_metaFields.push({
          name: 'twitter:title',
          content: meta_fields.twitter_title
        })
      }
      if (
        meta_fields.twitter_image !== undefined &&
        meta_fields.twitter_image !== ''
      ) {
        this.Mixin_metaFields.push({
          name: 'twitter:image',
          content: meta_fields.twitter_image
        })
      }
      if (
        meta_fields.twitter_description !== undefined &&
        meta_fields.twitter_description !== ''
      ) {
        this.Mixin_metaFields.push({
          name: 'twitter:description',
          content: meta_fields.twitter_description
        })
      }
      if (meta_fields.robots !== undefined && meta_fields.robots !== '') {
        this.Mixin_metaFields.push({
          name: 'robots',
          content: meta_fields.robots
        })
      }
      if (meta_fields.googlebot !== undefined && meta_fields.googlebot !== '') {
        this.Mixin_metaFields.push({
          name: 'googlebot',
          content: meta_fields.googlebot
        })
      }
      //console.log('metas', this.Mixin_metaFields)
    }
  }
}
