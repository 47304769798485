
import TrakkenButton from '~/components/ui/TrakkenButton.vue'
export default {
  asyncData({ req, app }) {
    // Load the JSON from the API
    let mode = 'published'
    if (process.server) {
      const draftArr = [
        'localhost:3000',
        'stage.trakken.de',
        'stage.trakken.at',
        'stage.trakken.ch',
        'stage.trakken.se',
        'stage.trakken.es',
        'stage.trakkenwebservices.com'
      ]
      mode = draftArr.indexOf(req.headers.host) >= 0 ? 'draft' : mode
    }
    return app.$storyapi
      .get('cdn/stories/en/errors/404/', {
        version: mode
      })
      .then((res) => {
        console.info(res)
        return {}
      })
      .catch((error) => {
        console.info('error', error)
        if (!res.response) {
          console.error(res)
          context.error({
            statusCode: 404,
            message: 'Failed to receive content from Storyblok API'
          })
        } else {
          console.error(res.response.data)
          context.error({
            statusCode: res.response.status,
            message: res.response.data
          })
        }
      })
  },
  mounted() {
    if (process.client) {
      this.errorMonitoring()
    }
    setTimeout(() => {
      location.replace(window.location.origin)
    }, 3000)
  },
  methods: {
    errorMonitoring() {
      let routerRoutes =
        this.$router !== undefined &&
        this.$router !== undefined &&
        this.$router.options !== undefined &&
        this.$router.options.routes !== undefined
          ? this.$router.options.routes
          : null
      let fromRoute = 'not Available'
      let toRoute = this.$router.history.current.path
      let origin = window.location.origin
      if (routerRoutes) {
        fromRoute = routerRoutes[routerRoutes.length - 1].path
      }
      let errorData = {
        from: origin + fromRoute,
        to: origin + toRoute
      }
      this.$store.dispatch('sendErrorMonitoring', errorData)
    }
  },
  name: 'nuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  components: {
    TrakkenButton
  }
}
