import axios from 'axios'
export const state = () => ({
  cacheVersion: '',
  language: 'en',
  //version: 'published',
  version: 'draft',
  contact: {},
  job: {},
  cookie: {},
  menus: {
    main_nav: {},
    footer_nav: {}
  },
  as_closed: false
})

export const mutations = {
  setAsClosed(state) {
    state.as_closed = true
  },
  setLang(state, language) {
    if (state.language !== language) {
      state.language = language
    }
  },
  setVersion(state, version) {
    if (state.version !== version) {
      state.version = version
    }
  },
  setMainNav(state, mainnav) {
    let runThroughItems = (obj) => {
      obj = obj.map((mainItem) => {
        if (mainItem.link) {
          mainItem.link = getRealLinkObj(mainItem.link)
        }
        if (mainItem.level1_detail && mainItem.level1_detail.link) {
          mainItem.level1_detail.link = getRealLinkObj(
            mainItem.level1_detail.link
          )
        }
        if (mainItem.level2) {
          mainItem.level2 = mainItem.level2.map((level2Item) => {
            if (level2Item.link) {
              level2Item.link = getRealLinkObj(level2Item.link)
            }
            if (level2Item.level3) {
              level2Item.level3 = level2Item.level3.map((level3Item) => {
                if (level3Item.link) {
                  level3Item.link = getRealLinkObj(level3Item.link)
                }
                return level3Item
              })
            }
            return level2Item
          })
        }
        return mainItem
      })
      return obj
    }
    let getRealLinkObj = (link) => {
      let unexpectedRoutes = Array('de', 'en', 'es')
      if (unexpectedRoutes.indexOf(link.cached_url.split('/')[0]) >= 0) {
        let delString =
          unexpectedRoutes[
            unexpectedRoutes.indexOf(link.cached_url.split('/')[0])
          ]
        link.cached_url = link.cached_url.replace(delString + '/', '')
      }
      return link
    }

    mainnav.products_and_tools = runThroughItems(mainnav.products_and_tools)
    mainnav.services_and_solutions = runThroughItems(
      mainnav.services_and_solutions
    )
    state.menus.main_nav = mainnav
  },
  setFooterNav(state, footernav) {
    state.menus.footer_nav = footernav
  },
  setContact(state, contactdetails) {
    state.contact = contactdetails
  },
  setJobdetails(state, jobDetails) {
    state.job = jobDetails
  },
  setGDPRDetails(state, GDPRDetails) {
    state.cookie = GDPRDetails
  },
  setPartnership(state, partnershipdetails) {
    state.partnership = partnershipdetails
  },
  setPartnershipCo(state, partnershipdetails) {
    state.partnershipCo = partnershipdetails
  },
  setCacheVersion(state, version) {
    state.cacheVersion = version
  }
}

export const actions = {
  loadNavigation({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/main-navigation', {
        version: context.version
      })
      .then((res) => {
        /* console.log('store set minNav from API', res.data.story.content._uid) */
        commit('setMainNav', res.data.story.content)
      })
  },
  loadFooterNavigation({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/footer-navigation', {
        version: context.version
      })
      .then((res) => {
        commit('setFooterNav', res.data.story.content)
      })
  },
  loadPartnership({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/partnership', {
        version: context.version
      })
      .then((res) => {
        commit('setPartnership', res.data.story.content)
      })
  },
  loadPartnershipCo({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/co-partnership', {
        version: context.version
      })
      .then((res) => {
        commit('setPartnershipCo', res.data.story.content)
      })
  },
  loadContact({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/contact-block', {
        version: context.version
      })
      .then((res) => {
        commit('setContact', res.data.story.content)
      })
  },
  loadJobdetails({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/job-detail', {
        version: context.version
      })
      .then((res) => {
        commit('setJobdetails', res.data.story.content)
      })
  },
  loadGDPRCookie({ commit }, context) {
    return this.$storyapi
      .get('cdn/stories/en/globals/cookie_banner', {
        version: context.version
      })
      .then((res) => {
        commit('setGDPRDetails', res.data.story.content)
      })
  },
  sendContactMail({ commit }, content) {
    return axios({
      method: 'post',
      url: '/api/contact',
      data: content
    }).then(
      (res) => {
        console.info('Response', res, commit, content)
      },
      (error) => {
        console.error(error)
      }
    )
  },
  sendEventRegistration({ commit }, content) {
    return axios({
      method: 'post',
      url: '/api/events',
      data: content
    }).then(
      (res) => {
        console.info('Response', res, commit, content)
      },
      (error) => {
        console.error('Event Registration Error', error)
        throw error
      }
    )
  },
  sendApplyMail({ commit }, content) {
    return axios({
      method: 'post',
      url: '/api/apply',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: content
    }).then(
      (res) => {
        console.info('Response', res, commit, content)
        for (var value of content.values()) {
          console.log(value)
        }
      },
      (error) => {
        console.error(error)
      }
    )
  },
  sendErrorMonitoring({ commit }, content) {
    return axios({
      method: 'post',
      url: '/api/error',
      data: content
    }).then(
      (res) => {
        console.info('Response', res, commit, content)
      },
      (error) => {
        console.error(error)
      }
    )
  }
}
export const getters = {
  getAsClosed(state) {
    return state.as_closed
  }
}
