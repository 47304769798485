import Vue from 'vue'
import * as VueGoogleMaps from '~/node_modules/vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPTYaN7g6PkoPAi1Zu5AIe3FB_ffz3U2M',
    libraries: 'places' // If you want to use places input
  },
  installComponents: true
})
