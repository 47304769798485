export default function (context) {
  const {
    app,
    store,
    route,
    // params,
    // query,
    // env,
    //isDev,
    // isHMR,
    req,
    // $gtm,
    redirect
    // error
  } = context
  let unexpectedRoutes = Array('de', 'en', 'es')

  if (unexpectedRoutes.indexOf(route.path.split('/')[1]) >= 0) {
    let delString =
      unexpectedRoutes[unexpectedRoutes.indexOf(route.path.split('/')[1])]
    redirect(route.path.replace(delString + '/', ''))
  } else {
    if (req) {
      let language = 'en'
      // const { req } = context
      switch (req.headers.host) {
        case 'www.trakkenwebservices.com':
        case 'www.trakken.se':
          language = 'en'
          break
        case 'www.trakken.es':
          language = 'es'
          break
        default:
          language = 'en'
      }
      store.commit('setLang', language)
      store.commit(
        'setVersion',
        //route.query._storyblok || isDev ? 'draft' : 'published'
        //test on stage for everything that is no published
        //route.query._storyblok || isDev ? 'draft' : 'draft'
        req.headers.host === 'www.trkkn.com' ||
          req.headers.host === 'testing.trkkn.com'
          ? 'published'
          : 'draft'
      )
    }
    if (process.server) {
      //console.log('lang detect', app.$storyapi)
      store.commit('setCacheVersion', app.$storyapi.cacheVersion)
      if (!store.state.menus._uid) {
        /* console.log('load navi detection', {
          version: store.state.version,
          language: store.state.language,
          cache: app.$storyapi.cacheVersion
        }) */
        store.dispatch('loadNavigation', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadFooterNavigation', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadPartnership', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadPartnershipCo', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadContact', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadJobdetails', {
          version: store.state.version,
          language: store.state.language
        })
        store.dispatch('loadGDPRCookie', {
          version: store.state.version,
          language: store.state.language
        })
      }
    }
  }
  return true
}
